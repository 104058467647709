import { createStore } from 'vuex'
import authModule from './auth.store';
import clientAreaModule from './clientArea.store'
import watMonitorModule from './watMonitor.store'
import lovModule from './lov.store'
import statisticsModule from './statistics.store'
import studyCreationModule from './studyCreation.store'
import executionModule from '~/store/execution.store'
import watuxModule from '~/store/watux.store'
import watFocusModule from '~/store/watfocus.store'
import adminModule from './admin.store'
export interface State {
  authStore: any,
  clientAreaModule: any,
  statisticsModule: any,
  studyCreationModule: any,
  executionModule: any,
  watuxModule: any,
  adminModule: any,
  watFocusModule: any
}

export default createStore<State>({
  modules: {
    authModule: authModule,
    clientAreaModule:clientAreaModule,
    watMonitorModule:watMonitorModule,
    lovModule:lovModule,
    statisticsModule:statisticsModule,
    studyCreationModule:studyCreationModule,
    executionModule: executionModule,
    watuxModule: watuxModule,
    adminModule: adminModule,
    watFocusModule: watFocusModule
  },
});
