import ApiService from '@/service'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from '@/i18n'
  const state = {
    clientData:{
      contactName:'',
      billingAddress: null,
      contactPhone: null
    },
    authToken: "",
    forceHideHeader:false
  }

  const getters = {
    isAuthenticated(state: any){
      return !!state.authToken
    },
    authToken(state: any){
      return state.authToken
    },
    clientData(state: any){
      return state.clientData
    },
    forceHideHeader(state: any){
      return state.forceHideHeader
    }
  }

  const actions = {
    async loginClient(context: any, payload:any) {
      return ApiService
        .post('/client/login ',payload)
        .then(({data}: any) => {
          if(data.payload == "mustChangePassword") context.dispatch('setCookieChangePassword')
          context.commit('setStateAuthtoken',data.authToken)
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    async getLoginType(context: any, payload:any) {
        return ApiService
          .post('/client/loginTypeCheck ',payload)
          .then(({data}: any) => {
            return data;
          })
          .catch(({response}: any)  => {
            return response;
          })
      },
    async getClientData(context: any, payload: any){
      return ApiService
        .get('/client', null)
        .then(({data}: any) => {
          context.commit('setStateClientData',data)
          i18n.global.locale = data.country || 'es'
          return data;
        })
        .catch(({response}: any)  => {
          return response;
        })
    },
    putClientData(context: any, payload: any){
      return ApiService
        .put('/client', payload)
        .then(({data}: any) => {
          context.dispatch('getClientData')
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    newPassword(context: any, payload: any){
      return ApiService
        .post('/client/newPassword', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    resetPassword(context: any, payload: any){
      return ApiService
        .post('/client/resetPassword', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    resetNewPassword(context: any, payload: any){
      return ApiService
        .post('/client/'+payload.userId+'/resetNewPassword', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    checkResetCode(context: any, payload: any){
      return ApiService
        .post('/client/'+payload.userId+'/checkResetCode', payload.data)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any)  => {
          return Promise.reject(response);
        })
    },
    send2FACode(context: any,payload: any) {
      return ApiService
        .post('/client/2facode', payload)
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any) => {
          return Promise.reject(response)
        });
    },
    resend2FACode(context: any,payload: any) {
      return ApiService
        .post('/client/resend2facode')
        .then(({data}: any) => {
          return data;
        })
        .catch(({response}: any) => {
          return Promise.reject(response)
        });
    },
    async getAuthTokenWithTempKey(context: any, payload: any) {
        const { tempKey } = payload;
        try {
            const {data} = await ApiService.get(`/client/token/${tempKey}`);
            // Guarda el token en una cookie
            context.dispatch('setCookieAuthtoken', data.authToken)
            context.commit('setStateAuthtoken',data.authToken)
            return data;
        } catch (error) {
            // Manejo de errores más detallado
            const { response } = error;
            if (response && response.status) {
                console.error(`Error ${response.status}: ${response.statusText}`);
            }
            return Promise.reject(response);
        }
    },
    
    setCookieChangePassword(){
      VueCookieNext.setCookie('mustChangePassword', 'true')
    },
    removeCookieChangePassword(){
      VueCookieNext.removeCookie('mustChangePassword')
    },
    setCookieNeeds2FA(){
      VueCookieNext.setCookie('needs2FA', 'true', { expire: 0 })
    },
    removeCookieNeeds2FA(){
      VueCookieNext.removeCookie('needs2FA')
    },
    setCookieAuthtoken(context: any, authtoken: string){
      VueCookieNext.setCookie("authtoken", authtoken, {
			path: "/",
			secure: "true",
			sameSite: "Strict",
		});
    },
    removeCookieAuthtoken(){
      VueCookieNext.removeCookie('authtoken')
    },
    closeSession(context:any){
      context.dispatch('removeCookieAuthtoken')
      context.commit('setStateAuthtoken',"")
    }
  }

  const mutations = {
    setStateAuthtoken(state: any,data: any){
      state.authToken = data;
    },
    setStateClientData(state: any, data: any){
      state.clientData = data;
    },
    setHideHeader(state: any, data: any){
      state.forceHideHeader = data
    }
  }

  if(VueCookieNext.getCookie('authtoken')){
    state.authToken = VueCookieNext.getCookie('authtoken')
  }

export default{
  state,
  getters,
  actions,
  mutations
}
